.tableClass .ant-table-thead .ant-table-cell {
  background: rgb(255, 255, 255, 0.15) !important;
  color: white;
}
.tableClass .ant-table-content {
  background: rgb(0, 0, 0, 0.8) !important;
  color: white;
}
.tableClass .ant-table-tbody > tr:hover > td {
  background-color: inherit !important;
}

.tableClass .ant-table-wrapper table {
  border-radius: 0% !important;
}
